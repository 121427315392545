// extracted by mini-css-extract-plugin
export var container = "styles-module--container--yYf7N";
export var clicked = "styles-module--clicked--joS49";
export var closeButton = "styles-module--closeButton---yqAv";
export var imageContainer = "styles-module--imageContainer--0BrB-";
export var college = "styles-module--college--Gda3n";
export var image = "styles-module--image--x+xek";
export var content = "styles-module--content--OofBZ";
export var tagContainer = "styles-module--tagContainer--boC27";
export var tag = "styles-module--tag--NmmZX";
export var title = "styles-module--title--ZWZvg";
export var quote = "styles-module--quote--nUUmu";